<template>

  <div>
    <!-- 面包屑导航区域 -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理管理</el-breadcrumb-item>
      <el-breadcrumb-item>培训文件管理</el-breadcrumb-item>
    </el-breadcrumb> -->

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- <el-form :inline="true" :model="queryInfo" class="demo-form-inline">
        
        <el-form-item label="标题">
          <el-input placeholder="请输入内容" style="width:130px" v-model="queryInfo.name" clearable @clear="getNewsList(1)">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getNewsList">查询</el-button>
          <el-button type="success" @click="showAddDialog()">添加新闻</el-button>
        </el-form-item>
        
      </el-form> -->

       <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.search" clearable @clear="getNewsList">
            <el-button slot="append" icon="el-icon-search" @click="getNewsList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="showAddDialog()">添加</el-button>
        </el-col>
      </el-row>

      <el-table :data="newsList" border stripe>
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="图标" prop="" width="150px">
          <template slot-scope="scope">
              <!-- scope.row获取当前行所有数据 作用域插槽获取当前行的数据（可以是图片连接如下：:src="scope.row.iconImgLarge"） -->
              <img v-if="scope.row.icon != ''" style="width: 100px; height: 50px" :src="scope.row.icon"/>
              <span v-if="scope.row.icon == ''">--</span>
            </template>
        </el-table-column>
        <el-table-column label="上下架" prop="" width="80px">
          <template slot-scope="scope">
              <!-- <span>{{scope.row.status_text}}</span> -->
              <el-tag :type="scope.row.status_text === '上架' ? 'primary' : 'danger'" disable-transitions>
                {{scope.row.status_text}}
              </el-tag>

            </template>
        </el-table-column>
        <el-table-column label="添加时间" prop="instime" width="150px"></el-table-column>
        <el-table-column label="操作" width="160px">
          <template slot-scope="scope">
            <!-- 删除按钮 -->
            <el-button type="primary" size="mini" @click="editNews(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="removeNews(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @current-change="handleCurrentChange" :current-page="queryInfo.page" layout="total, prev, pager, next" :total="total"></el-pagination>
    </el-card>

    <!-- 添加新闻的对话框 -->
    <el-dialog title="通知" :visible.sync="dialogVisible" width="70%" @close="addDialogClosed">
      
      <!-- 内容主体区域 -->
      <el-form :model="newsInfo" ref="addFormRules" label-width="80px" :rules="addFormRules">
          <el-form-item label="标题：" style="margin-left:60px" prop="title">
            <el-input placeholder="" style="width:500px" v-model="newsInfo.title" >
            </el-input>
          </el-form-item>

          <el-form-item label="上下架：" style="margin-left:60px" prop="status">
            <el-switch placeholder="" v-model="newsInfo.status" active-value="1" inactive-value="0">
            </el-switch>
          </el-form-item>

          <el-form-item label="图片：" prop="url" style="margin-left:60px">
            <img v-if="newsInfo.icon != ''" style="width: 200px; height: 100px" :src="newsInfo.icon"/>
            <p style="margin-bottom:0px; margin-top:0px; font-size:1px; color:gray">图片长宽比为：1.75:1 至 2.25:1</p>
            <!-- <el-upload action="" :show-file-list="false" :http-request="uploadImg" accept=".jpeg,.png,jpg">
              <el-button size="small" type="primary" style="margin-top:5px">点击上传</el-button>
            </el-upload>
            <el-button size="small" type="danger" style="margin-top:5px">清除</el-button> -->

            <el-row :gutter="5">
              <el-col :span="3">
                  <el-upload action="" :show-file-list="false" :http-request="uploadImg" accept=".jpeg,.png,jpg">
                    <el-button size="small" type="primary" style="margin-top:5px">点击上传</el-button>
                  </el-upload>
              </el-col>
              
              <el-col :span="2">
                <el-button  size="small" type="danger" @click="delImg()">清除</el-button>
              </el-col>
            </el-row>

          </el-form-item>

          <el-form-item prop="content">
            <quill-editor
              v-model="newsInfo.content"
              ref="quillEditor" 
              :options="editorOption"
              @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
              @change="onEditorChange($event)">
            </quill-editor>
          </el-form-item>

          <div style="display:none;">
            <el-upload class="edit-uploader" 
              action = ""
              :http-request="cosUploadRequest" 
              :show-file-list="false"
              :on-success="editorUploadSuccess"
              :on-error="editorUploadError"
              :before-upload="beforeEditorUpload" >
            </el-upload>
          </div>

      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrEditNews" >确 定</el-button>
      </span>

    </el-dialog>

  </div>

</template>

<script>
import { quillEditor } from "vue-quill-editor";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor
  },

  data() {
    return {
      queryInfo: {
        title: '',
        page: 1,
        limit: 10
      },

      isEdit: false,
      editId: 0,
      newsList: [],
      total: 0,

      dialogVisible: false,
      newsInfo: {},
      addFormRules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },

      editorOption: {
        modules: {
            toolbar: {
              container:[
                ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                ['image'], // 链接、图片、视频
                [{ header: 1 }, { header: 2 }], // 1、2 级标题
                [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                [{ indent: '-1' }, { indent: '+1' }], // 缩进
                // [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
                [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                [{ align: [] }], // 对齐方式
              ],

              handlers: {
              'image': function(value) {
                  if (value) {
                    document.querySelector('.edit-uploader input').click()
                  }
              }
        },
            }
        },

        readyOnly: false, // 是否只读
        theme: 'snow', // 主题 snow/bubble
        syntax: false, // 语法检测
      },
    }
  },
    methods: {
        // 失去焦点
        onEditorBlur(editor) {},
        // 获得焦点
        onEditorFocus(editor) {},
        // 开始
        onEditorReady(editor) {},
        // 值发生变化
        onEditorChange(editor) {
            this.content = editor.html
            console.log(editor)
        },
        beforeEditorUpload(){
          this.$loading.service();
        },

        //获取新闻列表
        async getNewsList(page){
          const res = await this.$curl('News/getNewsList', this.queryInfo)
          if (res === false) {
            return
          }

          this.newsList = res.data;
          this.total = res.count;
        },
       
        showAddDialog()
        {
          this.dialogVisible = true;
          this.isEdit = false;

          this.newsInfo = {};
        },

        addDialogClosed(){},

        async addOrEditNews(){
          let res = null

          if( this.isEdit ){
            let newsEditInfo = this.newsInfo;
            newsEditInfo.id = this.editId;
            res  = await this.$curl('news/editNews', newsEditInfo, true)
          }else{
            res  = await this.$curl('news/addNews', this.newsInfo, true)
          }

          if (res === false) {
            return
          }

          this.dialogVisible = false
          this.getNewsList()
        },

        editorUploadSuccess(err, data){
          let loading = this.$loading.service();
          loading.close();

          // 获取富文本组件实例
          let quill = this.$refs.quillEditor.quill
          // 如果上传成功
          if ( !err ) {
            // message.success("上传成功");
            console.log('上传成功', data);
            // 获取光标所在位置
              let length = quill.getSelection().index;
              // 插入图片  res.info为服务器返回的图片地址
              quill.insertEmbed(length, 'image', "https://" + data.Location)
              // 调整光标到最后
              quill.setSelection(length + 1)
          }else{
            this.$message.error('图片插入失败')
          }
        },

        // 富文本图片上传失败
        editorUploadError() {
            // loading动画消失
            // this.$Loading.close();
            this.$message.error('图片插入失败')
        },

        cosUploadRequest(options){
          this.$uploadCos(options, this.editorUploadSuccess);
        },

        quillImageHandler(value) {
          if (value) {
              document.querySelector('.edit-uploader input').click()
          }
        },

        editNews(row)
        {
          this.dialogVisible = true;
          this.newsInfo = {
            title: row.title,
            status: row.status,
            content: row.content,
            icon: row.icon,
          };

          this.isEdit = true;
          this.editId = row.id;
        },

        uploadImg(options){
          // this.fileInfo.name = options.file.name;
          // this.fileInfo.size = options.file.size;
      
          // if( options.file.size > 1024 ){
          //   this.fileInfo.size_h = (options.file.size/1024).toFixed(2) + "K";
          // }else{
          //   this.fileInfo.size_h = options.file.size + "B";
          // }
          this.$loading.service();
          this.$uploadCos(options, this.imgUploadSuccess);
        },

        //清楚图片
        delImg(){
          this.newsInfo.icon = "";
        },

        imgUploadSuccess(err, data){
          let loading = this.$loading.service();
          loading.close();

          // 如果上传成功
          if ( !err ) {
            this.$message.success("上传成功");
            console.log('上传成功', data);

            this.newsInfo.icon = "https://" + data.Location;
          }else{
            this.$message.error('上传失败')
          }
        },

        async removeNews(data) {
          const confirmResult = await this.$confirm(
          '此操作将永久删除该通知, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
          ).catch(err => err)

          // 如果用户确认删除，则返回值为字符串 confirm
          // 如果用户取消了删除，则返回值为字符串 cancel
          if (confirmResult !== 'confirm') {
            return
          }

          const res = await this.$curl("news/deleteNews", {id:data}, true)
          this.getNewsList()

          if (res === false) {
            return
          }
        },

        // 监听 页码值 改变的事件
        handleCurrentChange(newPage) {
          this.queryInfo.page = newPage
          this.getNewsList()
        },
    },

    mounted() {
      // this.$refs.quillEditor.getModule('toolbar').addHandler('image', this.quillImageHandler)
    },

    created(){
        this.getNewsList();
    }
}
</script>
<style lang='less' scoped>
  // .editor {
  //   line-height: normal !important;
  //   height: 400px;
  // }
  // .ql-snow .ql-tooltip[data-mode=link]::before {
  //   content: "请输入链接地址:";
  // }
  // .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  //     border-right: 0px;
  //     content: '保存';
  //     padding-right: 0px;
  // }

  // .ql-snow .ql-tooltip[data-mode=video]::before {
  //     content: "请输入视频地址:";
  // }

  // .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  // .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  //   content: '14px';
  // }
  // .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  //   content: '10px';
  // }
  // .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  //   content: '18px';
  // }
  // .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  // .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  //   content: '32px';
  // }

  // .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  // .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  //   content: '文本';
  // }
  // .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  // .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  //   content: '标题1';
  // }
  // .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  // .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  //   content: '标题2';
  // }
  // .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  // .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  //   content: '标题3';
  // }
  // .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  // .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  //   content: '标题4';
  // }
  // .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  // .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  //   content: '标题5';
  // }
  // .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  // .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  //   content: '标题6';
  // }

  // .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  // .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  //   content: '标准字体';
  // }
  // .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
  // .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  //   content: '衬线字体';
  // }
  // .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
  // .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  //   content: '等宽字体';
  // }
  </style>