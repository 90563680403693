import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Loading } from 'element-ui'
import './plugins/element.js'
import cos from 'cos-js-sdk-v5';
//导入全局样式
import './assets/css/global.css'
//导入图标
import './assets/fonts/iconfont.css'

import axios from 'axios'
//设置请求的根路径
axios.defaults.baseURL = 'http://hrapi.tylang.com/admin/'
//设置请求拦截器
axios.interceptors.request.use(config => {
  config.headers.token = window.localStorage.getItem('token')
  return config
}
)

Vue.prototype.$http = axios
Vue.prototype.$Loading = Loading

Vue.prototype.$curl = async function(url, param, showMsg=false){
  const { data:res } = await this.$http.post(url, param)

  if (res.code !== 0) {
    this.$message.error(res.msg)
    return false
  }else{
    if( showMsg ){
      this.$message.success(res.msg)
    }

    //如果没有返回data，则格式成空数组
    if( res.data == null ){
      return []
    }else{
      return res.data
    }
  }
}

Vue.prototype.$upload = async function(url, param){
  const res = await this.$http.post(url, param, {'Content-type':'multipart/form-data'})
  if( res.status == 200 ){
    return true
  }else{
    return false
  }
}

Vue.prototype.$uploadCos = async function cosUploadRequest(options, callback) {
  let file = options.file; // 拿到 file
  let fileName = file.name

  const res = await this.$curl('Group/getCosInfo')
  if( res === false ){
    return false
  }

  var cosObj = new cos({
    getAuthorization: function (options, callback) {
      callback({
        TmpSecretId: res.id,
        TmpSecretKey: res.key,
        SecurityToken: res.sessionToken,
        StartTime: res.startTime,
        ExpiredTime: res.expiredTime,
        });
      }
  });

  /* 直接调用 cos sdk 的方法 */
  cosObj.uploadFile({
    Bucket: 'huairou-1318373156', /* 填写自己的 bucket，必须字段 */
    Region: 'ap-beijing',     /* 存储桶所在地域，必须字段 */
    Key: fileName,              /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
    Body: file, // 上传文件对象
    SliceSize: 1024 * 1024 * 1024,

    onProgress: function(progressData) {
    }
    }, 
    
    callback
    );
}

Vue.prototype.$uploadFile = async function ossUploadRequest(options, supportFile) {
  const res = await this.$curl('upload/getAliPolicy')
  if( res === false ){
    return false
  }

  let file = options.file; // 拿到 file
  let fileName = file.name
  let date = new Date().getTime()
  let fileNames = `${date}_${fileName}` // 拼接文件名，保证唯一，这里使用时间戳+原文件名

  //获取文件后缀名称
  let fileNameInfo = fileName.split('.');
  if( fileNameInfo.length !== 2 ){
    this.$message.error("不支持该文件格式")
    return false
  }

  if( supportFile.indexOf(fileNameInfo[1]) == -1 ){
    this.$message.error("不支持该文件格式")
    return false
  }

  let loading = Loading.service()
  const formData = new FormData()
  formData.append('key', res.dir + fileNames)
  formData.append('policy', res.policy)
  formData.append('OSSAccessKeyId', res.accessid)
  formData.append('success_action_status', 200)
  formData.append('signature', res.signature)
  formData.append('file', file)
  const uploadRes = await this.$upload(res.host, formData)
  loading.close()

  if( uploadRes ){
    let uploadInfo = {
      "name": fileName,
      "url": res.host+"/"+res.dir+""+fileNames,
      "type": fileNameInfo[1],
    }
    return uploadInfo

  }else{
    this.$message.error("上传失败")
    return false
  }
}

function times(num1, num2) {
  const num1String = num1.toString()
  const num2String = num2.toString()
  const num1Digits = (num1String.split('.')[1] || '').length
  const num2Digits = (num2String.split('.')[1] || '').length
  const baseNum =  Math.pow(10, num1Digits + num2Digits)
  return Number(num1String.replace('.', '')) * Number(num2String.replace('.', '')) / baseNum
}

Vue.prototype.$add = function plus(num1, num2) {
  const num1Digits = (num1.toString().split('.')[1] || '').length
  const num2Digits = (num2.toString().split('.')[1] || '').length
  const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits))
   return (times(num1, baseNum) + times(num2, baseNum)) / baseNum
}

Vue.prototype.$formatDate = function formatDate(datetime) {
  if( !datetime ){
      return "";
  }
  function addDateZero(num) {
      return (num < 10 ? "0" + num : num);
  }
  let d = new Date(datetime);
  let formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate());
  return formatdatetime;
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
