<template>
  <div>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.search" clearable @clear="getStaffList">
            <el-button slot="append" icon="el-icon-search" @click="getStaffList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="showAddDialog()">添加</el-button>
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="staffList" border stripe>
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="部门" prop="depText"></el-table-column>
        <el-table-column label="手机" prop="phone"></el-table-column>
        <el-table-column label="是否可用" prop="statusText">
          <template slot-scope="scope">
              <el-tag :type="scope.row.statusText === '是' ? 'primary' : 'danger'" disable-transitions>
                {{scope.row.statusText}}
              </el-tag>

            </template>
        </el-table-column>
        <el-table-column label="角色" prop="roleText"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button v-if="managerInfo.role>0" type="primary" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <!-- <el-button v-if="managerInfo.role>0" type="danger" size="mini" @click="showUpdatePwdDialog(scope.row.id)">修改密码</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @current-change="handleCurrentChange" :current-page="queryInfo.page" layout="total, prev, pager, next" :total="total"></el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog title="员工信息" :visible.sync="dialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主体区域 -->
      <el-form :model="staffInfo" :rules="addFormRules" ref="addFormRef" label-width="70px">
        
        <el-form-item label="部门" prop="dep">
          <el-select v-model="staffInfo.dep" clearable style="width: 300px">
              <el-option v-for="item in depList" :label="item.name" :value="item.id" :key="item.id"/>
            </el-select>
        </el-form-item>
        
        <el-form-item label="姓名" prop="name">
          <el-input v-model="staffInfo.name" style="width: 300px"></el-input>
        </el-form-item>

        <el-form-item label="手机" prop="phone">
          <el-input v-model="staffInfo.phone" style="width: 300px"></el-input>
        </el-form-item>

        <el-form-item label="是否可用" prop="sex">
          <el-radio-group v-model="staffInfo.status">
            <el-radio label="1">可用</el-radio>
            <el-radio label="-1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="角色" prop="sex" style="" v-if="managerInfo.role==1">
          <el-radio-group v-model="staffInfo.role">
            <el-radio label="0">普通员工</el-radio>
            <el-radio label="2">子管理员(部门领导)</el-radio>
            <el-radio label="1">系统管理员(最高权限)</el-radio>
          </el-radio-group>
        </el-form-item>
        
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrEditService">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改对话框 -->
    <el-dialog title="修改密码" :visible.sync="updatePwdDialogVisible" width="30%" @close="updatePwdDialogClosed">
      <!-- 内容主体区域 -->
      <el-form :model="pwdInfo" :rules="pwdFormRules" ref="pwdFormRules" label-width="80px">
        
        <el-form-item label="密码" prop="pwd">
          <el-input v-model="pwdInfo.pwd" show-password></el-input>
        </el-form-item>

        <el-form-item label="确认密码" prop="pwd">
          <el-input v-model="pwdInfo.pwdConfirm" show-password></el-input>
        </el-form-item>

      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="updatePwdDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePwd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

export default {
  data() {
    return {
      // 获取列表的参数对象
      queryInfo: {
        search: '',
        // 当前的页数
        page: 1,
        // 当前每页显示多少条数据
        limit: 10
      },
      staffList: [],
      total: 0,
      // 控制添加用户对话框的显示与隐藏
      dialogVisible: false,
      // 添加用户的表单数据
      staffInfo: {
        name: '',
        phone: '',
        station: "",
        status: "",
        role:""
      },

      //管理员info
      managerInfo:{
         
      },

      //编辑或添加
      isEdit: false,
      editId: 0,

      //部门列表
      depList: [],

      //修改密码
      updatePwdDialogVisible:false,
      updatePwdId:0,

      pwdInfo:{
        pwd:"",
        pwdConfirm:"",
      },

      // 添加表单的验证规则对象
      addFormRules: {
        name: [
          { required: true, message: '请输入服务名称', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '名称的长度在2~10个字符之间',
            trigger: 'blur'
          }
        ],

        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ]
      },

      // 添加表单的验证规则对象
      pwdFormRules: {
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 4,
            max: 20,
            message: '密码的长度在4~20个字符之间',
            trigger: 'blur'
          }
        ],

        pwdConfirm: [
          { required: true, message: '再输入一次', trigger: 'blur' },
          {
            min: 4,
            max: 20,
            message: '密码的长度在4~20个字符之间',
            trigger: 'blur'
          }
        ],
      },

    }
  },
  created() {
    this.getManagerInfo()
    this.getStaffList()
    this.getDepList()
  },
  methods: {
    async getStaffList() {
      const res = await this.$curl('manager/getStaffList', this.queryInfo)
      if (res === false) {
        return
      }

      this.staffList = res.data
      this.total = res.count
    },
    
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getStaffList()
    },
   
    // 监听添加用户对话框的关闭事件
    addDialogClosed() {
      this.staffInfo = {
        name: '',
        phone: '',
        station: "",
        status: ""
      }
    },

    //
    updatePwdDialogClosed() {
      this.$refs.pwdFormRules.resetFields()
    },

    // 点击按钮，添加或更新
    addOrEditService(id=0) {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid){
          return
        }
        
        let res = null
        if( this.isEdit ){
          let staffEditInfo = this.staffInfo
          staffEditInfo.id = this.editId
          res  = await this.$curl('manager/editStaff', staffEditInfo, true)
        }else{
          res  = await this.$curl('manager/addStaff', this.staffInfo, true)
        }

        if (res === false) {
          return
        }

        // 隐藏添加用户的对话框
        this.dialogVisible = false
        // 重新获取用户列表数据
        this.getStaffList()
      })
    },

    //展示添加对话框
    showAddDialog(){
      this.isEdit = false
      this.dialogVisible = true
    },

    // 展示编辑用户的对话框
    async showEditDialog(id) {
      this.isEdit = true
      const res = await this.$curl('manager/getStaffById', {id:id})
      if( res === false ){
        return
      }

      this.staffInfo = res
      this.dialogVisible = true
      this.editId = id
    },

    //修改密码
    showUpdatePwdDialog(id){
      this.updatePwdId = id
      this.updatePwdDialogVisible = true
    },

    async updatePwd(){
      this.pwdInfo.id = this.updatePwdId;
      const res = await this.$curl('manager/editPwd', this.pwdInfo)
      if( res === false ){
        return
      }

      this.updatePwdDialogVisible = false
    },

    //获取驿站列表
    async getDepList() {
      const res = await this.$curl('manager/getDepList')
      if (res === false) {
        return
      }

      this.depList = res
    },

    async getManagerInfo() {
      const res = await this.$curl('manager/getManagerInfo')
      if (res === false) {
        return
      }

      this.managerInfo = res
    },
    
  }
}
</script>

<style lang="less" scoped>
</style>
