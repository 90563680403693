<template>
  <div>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.search" clearable @clear="getlogList">
            <el-button slot="append" icon="el-icon-search" @click="getlogList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="logList" border stripe>
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column label="模块" prop="modelName" width="150px"></el-table-column>
        <el-table-column label="操作人员" prop="extra_para_one" width="100px"></el-table-column>
        <el-table-column label="操作类型" prop="operateType" width="150px"></el-table-column>
        <el-table-column label="摘要" prop="msg"></el-table-column>
        <el-table-column label="操作时间" prop="instime" width="150px"></el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @current-change="handleCurrentChange" :current-page="queryInfo.page" layout="total, prev, pager, next" :total="total"></el-pagination>
    </el-card>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取列表的参数对象
      queryInfo: {
        search: '',
        // 当前的页数
        page: 1,
        // 当前每页显示多少条数据
        limit: 10
      },
      logList: [],

    }
  },
  created() {
    this.getlogList()
  },
  methods: {
    async getlogList() {
      const res = await this.$curl('operate/getlog', this.queryInfo)
      if (res === false) {
        return
      }

      this.logList = res.data
      this.total = res.count
    },
    
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getlogList()
    },
  }
}
</script>

<style lang="less" scoped>
</style>
