import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import News from '../components/operator/News.vue'
import GroupFile from '../components/operator/GroupFile.vue'
import Staff from '../components/operator/Staff.vue'
import Operate from '../components/operator/Operate.vue'
import Welcome from '../components/Welcome.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
  
    {
      path: '/home',
      component: Home,
      redirect: '/welcome',
      children: [
        { path: '/welcome', component: Welcome },
        { path: '/news', component: News },
        { path: '/staff', component: Staff },
        { path: '/groupFile', component: GroupFile },
        { path: '/operate', component: Operate },
      ]
    },
  ]
})

//挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
  if (to.path === '/login'){
    return next()
  }
  
  // 获取token
  const tokenStr = window.localStorage.getItem('token')
  if (!tokenStr) {
    return next('/login')
  }else{
    next()
  }

})

export default router
