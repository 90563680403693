<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <img style="width:200px;" src="../assets/logo.png" alt="">
        <!-- <span>北京燃气怀柔有限公司</span> -->
      </div>
      <!-- <el-button type="info" @click="logout">退出</el-button> -->

      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">{{managerInfo.name}}<i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
          <el-dropdown-item command="exit">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </el-header>
    
    <!-- 页面主体区域 -->
    <el-container>

      <!-- 侧边栏 -->
      <!-- <el-aside :width="isCollapse ? '64px' : '160px'"> -->
        <!-- <div class="toggle-button" @click="toggleCollapse">|||</div> -->
        <!-- 侧边栏菜单区域 -->
        <!-- <el-menu background-color="#333744" text-color="#fff" active-text-color="#409EFF" unique-opened :collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
          <el-submenu :index="item.id + ''" v-for="item in menulist" :key="item.id">
            <template slot="title">
              <span>{{item.name}}</span>
            </template>

            <el-menu-item :index="'/' + subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState('/' + subItem.path)">
              <template slot="title">
                <span>{{subItem.name}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu> -->

      <!-- </el-aside> -->

      <el-aside :width="isCollapse ? '64px' : '160px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu background-color="#333744" text-color="#fff" active-text-color="#409EFF" unique-opened :collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
            <el-menu-item :index="'/' + subItem.path" v-for="subItem in menulist" :key="subItem.id" @click="saveNavState('/' + subItem.path)">
              <template slot="title">
                <span>{{subItem.name}}</span>
              </template>
            </el-menu-item>
        </el-menu>
      </el-aside>

      <!-- 右侧内容主体 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>

    </el-container>

    <!-- 修改对话框 -->
    <el-dialog title="修改密码" :visible.sync="updatePwdDialogVisible" width="30%" @close="updatePwdDialogClosed">
      <!-- 内容主体区域 -->
      <el-form :model="pwdInfo" ref="pwdFormRules" label-width="80px">
        
        <el-form-item label="密码" prop="pwd">
          <el-input v-model="pwdInfo.pwd" show-password></el-input>
        </el-form-item>

        <el-form-item label="确认密码" prop="pwd">
          <el-input v-model="pwdInfo.pwdConfirm" show-password></el-input>
        </el-form-item>

      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="updatePwdDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePwd">确 定</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menulist: [],
      // 是否折叠
      isCollapse: false,
      // 被激活的链接地址
      activePath: '',

      managerInfo:{
        
      },

      //修改密码
      updatePwdDialogVisible:false,
      pwdInfo:{
        pwd:"",
        pwdConfirm:"",
      },
    }
  },
  created() {
    this.getManagerInfo()
    this.getMenuList()
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    async logout() {
      await this.$curl('manager/logOut')

      window.localStorage.clear()
      this.$router.push('/login')
    },

    // 获取所有的菜单
    async getMenuList() {
      const res = await this.$curl('manager/getOperateList')
        if( res === false ){
          return
      }

      this.menulist = res
    },

    // 点击按钮，切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },

    async getManagerInfo() {
      const res = await this.$curl('manager/getManagerInfo')
      if (res === false) {
        return
      }

      this.managerInfo = res
    },

    handleCommand(command){
      if( command == 'exit' ){
        this.logout();
      }

      if( command == 'changePwd' ){
        this.updatePwdDialogVisible = true;
      }
    },

    updatePwdDialogClosed() {
      this.$refs.pwdFormRules.resetFields()
    },

    async updatePwd(){
      this.pwdInfo.id = this.updatePwdId;
      const res = await this.$curl('manager/editPwd', this.pwdInfo, true)
      if( res === false ){
        return
      }

      this.updatePwdDialogVisible = false
    },
  }
}
</script>

<style lang="less" scoped>

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
.el-icon-arrow-down {
    font-size: 12px;
  }

.home-container {
  height: 100%;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;
  .el-menu {
    border-right: none;
    span {
      font-size: 12px;
    }
  }
}

.el-main {
  background-color: #eaedf1;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
