<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- <el-form :inline="true" :model="queryInfo" class="demo-form-inline">
        
        <el-form-item label="名称">
          <el-input placeholder="请输入内容" style="width:130px" v-model="queryInfo.name" clearable @clear="getUserList(1)">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getFileList">查询</el-button>
        </el-form-item>
        
      </el-form> -->

      <!-- 面包屑 -->
      <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-top:10px; margin-bottom:10px;">
        <el-breadcrumb-item v-for="item,index in filePath" :key="index" @click.native="filePathClick(item)">{{item.name}}</el-breadcrumb-item>
      </el-breadcrumb>
      <hr style="border: none;height: 1px; background-color: #E7EAE0 ;"/>

      <div v-if="nowDir>0">
        <el-button size="mini" type="success" @click="showAddDirDialog()">添加目录</el-button>
        <el-button size="mini" type="warning" @click="showAddFileDialog">添加文件</el-button>
      </div>

      <el-table :data="fileList" border stripe style="margin-top:5px">
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column label="名称" prop="name">
          <template slot-scope="scope">
            <div @click="fileRowClick(scope.row)">
              <span v-if="scope.row.isDir==1" style="color: #409EFF">{{ scope.row.name }}</span>
              <span v-if="scope.row.isDir==0">{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="大小" prop="size"></el-table-column>
        <el-table-column label="是否引用" prop="isYinYong"></el-table-column>
        <el-table-column label="操作人" prop="operator"></el-table-column>
        <el-table-column label="更新时间" prop="uptime"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 删除按钮 -->
            <el-button v-if="scope.row.isDir==1 && scope.row.oid==0" type="success" size="mini" @click="showEditDirDialog(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.up_id>0" type="danger" size="mini" @click="remoreDirFile(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加文件的对话框，一二目录 -->
    <el-dialog title="文件" :visible.sync="dialogFileVisible" :width="isYinyongFile==1 ? '50%' : '30%'">
      <div style="margin-left:10px; margin-bottom:20px">
        <el-radio v-model="isYinyongFile" label="1">引用文件</el-radio>
        <el-radio v-model="isYinyongFile" label="0">上传文件</el-radio>
      </div>

      <div style="margin-left:10px" v-if="isYinyongFile == '1'">
        <el-tree ref="fileTree" node-key="id" :props="treeProps" accordion :load="loadFile" check-strictly lazy show-checkbox @check-change="handleCheckChange"></el-tree>
      </div>

      <div v-if="isYinyongFile == '0'">
        <el-form :model="fileInfo" ref="addFormRef">
        <el-form-item label="" prop="url" style="margin-left:10px">
            <el-upload action="" :show-file-list="false" 
              :http-request="uploadFile">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <p>名称：{{fileInfo.name}}</p>
            <p>大小：{{fileInfo.size_h}}</p>
            <p>路径：{{filePathStr}}</p>
        </el-form-item>

      </el-form>
      </div>

      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFileVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFile">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加目录的对话框 -->
    <el-dialog title="目录" :visible.sync="dirDialogVisible" width="30%">

      <div style="margin-left:10px; margin-bottom:20px">
        <el-radio v-model="isYinyongDir" label="1">引用目录</el-radio>
        <el-radio v-model="isYinyongDir" label="0">添加目录</el-radio>
      </div>

      <div v-if="isYinyongDir == '1'"><el-tree ref="dirTree" node-key="id" :props="treeProps" accordion :load="loadDir" check-strictly lazy show-checkbox @check-change="handleDirheckChange"></el-tree></div>

      <div v-if="isYinyongDir == '0'">
        <!-- 内容主体区域 -->
        <el-form :model="dirInfo" label-width="90px">

          <el-form-item label="目录名称：">
            <el-input style="width:160px" v-model="dirInfo.name">
            </el-input>
          </el-form-item>

          <el-form-item label="所属目录：">
            <span>{{filePathStr}}</span> 
          </el-form-item>

        </el-form>
      </div>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dirDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrEditDir" >确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑目录的对话框 -->
    <el-dialog title="目录" :visible.sync="editDirDialogVisible" width="30%">

      <div>
        <!-- 内容主体区域 -->
        <el-form :model="dirInfo" label-width="70px">

          <el-form-item label="目录名称">
            <el-input style="width:160px" v-model="dirInfo.name">
            </el-input>
          </el-form-item>

          <span>所属目录：{{filePathStr}}</span> 
        </el-form>
      </div>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDirDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrEditDir" >确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

export default {
  data() {
    return {
      // 获取列表的参数对象
      queryInfo: {
        dirId: 0,
        name: '',
      },

      fileList: [],

      //面包屑
      filePath: [
        {dirId:0, name:"首页"},
      ],
      filePathStr: "",

      //当前目录
      nowDir: 0,

      // 控制添加文件对话框的显示与隐藏
      dialogVisible: false,

      // 控制添加目录对话框的显示与隐藏
      dirDialogVisible: false,
      isEditDir: false,
      editDirId: 0,
      dirInfo: {
        name: '',
        up_id: '',
      },

      //树形文件列表
      dialogFileVisible: false,
      isYinyongFile: '1',
      treeFileInit: [],

      //树形目录列表
      isYinyongDir: '1',
      treeDirInit: [],

      treeProps:{
        label: 'name',
        children: 'children',
        disabled: 'disabled',
        isLeaf: 'isLeaf'
      },

      //编辑目录
      editDirDialogVisible: false,

      fileInfo: {
        oid: "",
        name: '',
        up_id: '',
        url: '',
        size: '',
        size_h: '',
      },

      //编辑或添加
      isEdit: false,
      editId: 0,
    }
  },
  created() {
    this.getFileList(0);
    this.getTreeFileInit(0);
    this.getTreeDirInit(0);
  },
  methods: {
    getfilePathStr(){
      this.filePathStr = "";
      let i = 0;
      for(var filePathItem of this.filePath){
        if( i == 0 ){
          this.filePathStr += filePathItem.name;
        }else{
          this.filePathStr += " > " + filePathItem.name;
        }
        i++;
      }
    },

    loadFile(node, resolve){
        console.log(node);
        if( node.level == 0 ){
          return resolve(this.treeFileInit);
        }else{
          return this.requestTree(node.data, resolve);
        }
    },

    loadDir(node, resolve){
        if( node.level == 0 ){
          return resolve(this.treeDirInit);
        }else{
          return this.requestTreeDir(node.data, resolve);
        }
    },

    async requestTree(nodeData, resolve){
      let queryInfo = {dirId:nodeData.id};
      const res = await this.$curl('group/getTreeFileList', queryInfo)
      if (res === false) {
        resolve([]);
      }else{
        resolve(res);
      }
    },

    async requestTreeDir(nodeData, resolve){
      let queryInfo = {dirId:nodeData.id};
      const res = await this.$curl('group/getTreeDirList', queryInfo)
      if (res === false) {
        resolve([]);
      }else{
        resolve(res);
      }
    },

    handleCheckChange(data, checked){
      if (checked){
        this.fileInfo.oid = data.id;
        this.$refs.fileTree.setCheckedNodes([data])
      }
    },

    handleDirheckChange(data, checked){
      if (checked){
        this.$refs.dirTree.setCheckedNodes([data])
      }
    },

    fileRowClick(rowData, index){
      if( rowData.dirId == undefined ){
        return;
      }

      let filePathItem = {dirId:rowData.dirId, name:rowData.name};
      this.filePath.push(filePathItem)

      this.getfilePathStr();
      this.nowDir = rowData.dirId;
      this.getFileList(rowData.dirId);
    },

    filePathClick(filePathInfo){
      let filePathTemp = [];
      for(var filePathItem of this.filePath){
        filePathTemp.push(filePathItem);
        if( filePathItem.dirId == filePathInfo.dirId ){
          break;
        }
      }
      this.filePath = filePathTemp;

      this.getfilePathStr();
      this.nowDir = filePathInfo.dirId;
      this.getFileList(filePathInfo.dirId);
    },

    async getFileList(dirId) {
      let queryInfo = {dirId:dirId};
      const res = await this.$curl('group/getFileList', queryInfo)
      if (res === false) {
        return
      }

      this.fileList = res.dir.concat( res.file)
    },

    async getTreeFileInit(dirId) {
      let queryInfo = {dirId:dirId};
      const res = await this.$curl('group/getTreeFileList', queryInfo)
      if (res === false) {
        return
      }

      this.treeFileInit = res;
    },

    async getTreeDirInit(dirId) {
      let queryInfo = {dirId:dirId};
      const res = await this.$curl('group/getTreeDirList', queryInfo)
      if (res === false) {
        return
      }

      this.treeDirInit = res;
    },
    
    //展示添加对话框
    showAddFileDialog(){
      this.dialogFileVisible = true;

      this.fileInfo = {
        oid: 0,
        name: '',
        up_id: '',
        url: '',
        size: '',
        size_h: '',
      }
    },

    //展示添加目录对话框
    showAddDirDialog(){
      this.dirDialogVisible = true;
      this.isEditDir = false;
      this.$refs.dirTree.setCheckedNodes([])

      this.dirInfo.name = "";
      this.dirInfo.up_id = 0;
    },
    showEditDirDialog(row){
      this.editDirDialogVisible = true;

      this.isEditDir = true;
      this.editDirId = row.id;
      this.dirInfo.name = row.name;
      this.dirInfo.up_id = row.up_id;
    },

    async addOrEditDir(){
      let res = null

      this.dirInfo.up_id = this.nowDir;
      if( !this.isEditDir && this.isYinyongDir == '1' && this.$refs.dirTree.getCheckedKeys().length >0 ){
          this.dirInfo.oid = this.$refs.dirTree.getCheckedKeys()[0];
      }else{
        this.dirInfo.oid = 0;
      }

      if( this.isEditDir ){
        let originalDirEditInfo = this.dirInfo;
        originalDirEditInfo.id = this.editDirId;
        res  = await this.$curl('group/editDir', originalDirEditInfo, true)
      }else{
        res  = await this.$curl('group/addDir', this.dirInfo, true)
      }

      if (res === false) {
        return
      }

      this.dirDialogVisible = false;
      this.editDirDialogVisible = false;
      this.getFileList(this.nowDir)
    },


    //上传文件成功
    fileUploadSuccess(err, data) {
      let loading = this.$loading.service();
      loading.close();

      // 如果上传成功
      if ( !err ) {
        this.$message.success("上传成功");
        console.log('上传成功', data);

        this.fileInfo.url = "https://" + data.Location;
      }else{
        this.$message.error('上传失败')
      }
    },

    uploadFile(options){
      this.fileInfo.name = options.file.name;
      this.fileInfo.size = options.file.size;
      
      if( options.file.size > 1024 ){
        this.fileInfo.size_h = (options.file.size/1024).toFixed(2) + "K";
      }else{
        this.fileInfo.size_h = options.file.size + "B";
      }
      
      this.$loading.service();
      this.$uploadCos(options, this.fileUploadSuccess);
    },

    async addFile(){
      let res = null

      this.fileInfo.up_id = this.nowDir;
      res  = await this.$curl('group/addFile', this.fileInfo, true)

      if (res === false) {
        return
      }

      this.dialogFileVisible = false
      this.getFileList(this.nowDir)
    },

    //删除目录或文件
    async remoreDirFile(data) {
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm(
        '此操作将永久删除该文件, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)

      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      if (confirmResult !== 'confirm') {
        return
      }

      if( data.isDir == 1 ){
        const res = await this.$curl("group/delDir", {id:data.id}, true)
      }else{
        const res = await this.$curl("group/delFile", {id:data.id}, true)
      }
      this.getFileList(this.nowDir)

      if (res === false) {
        return
      }
    },
    
  }
}
</script>

<style lang="less" scoped>
.el-input{
  width: 300px;
}

</style>
